import React from 'react'

// Images 
import bookImg from '../../assets/images/why-we/booking-1.jpg'
import bookShape from '../../assets/images/shape/shape3.png'

const GetQuote = () => {
    return (
        <section className="booking ptb-100 bg-f8f8f8">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="booking-form-area pr-20">
                            <div className="default-section-title">
                                <span>Get A Quote</span>   
                                <h3>Fill Out This Form Now</h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, do eiusmod tempo incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrice. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            <form> 
                                <div className="row">
                                    <div className="col-lg-6">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Your Name*" 
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <input 
                                            type="email" 
                                            className="form-control" 
                                            placeholder="Your Email*" 
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Website Link*" 
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <textarea 
                                            className="form-control" 
                                            rows="5" 
                                            placeholder="Your Message*">
                                        </textarea>
                                    </div>
                                    <div className="col-lg-12">
                                        <button className="default-button" type="submit">Get A Quote <i className="fas fa-arrow-right"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="booking-img">
                            <img 
                                src={bookImg} 
                                alt="Book" 
                            />
                            <div className="booking-shape">
                                <img 
                                    src={bookShape} 
                                    alt="Book" 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GetQuote
