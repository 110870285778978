import React from 'react';
import Navbar from '../_App/Navbar';

const Topheader = () => {
    return (
        <div className="header-area header-1">
            <div className="topbar">
                <div className="container">
                    <div className="topbar-content topbar-content-1 bg-071327">
                        <div className="row align-items-center">
                            <div className="col-xl-8 col-lg-9 col-md-12 col-sm-12 col-12">
                                <div className="topbar-left-side">
                                    <ul>
                                        <li><i className="fas fa-phone"></i> <a href="tel:+029222110">Call: +(029-22-2110)</a></li>
                                        <li><i className="fas fa-map-marker-alt"></i> <a href="https://goo.gl/maps/ef9GLfDoaqKAS4i17">Address: 32 Street Name, New York, USA</a></li>
                                        <li><i className="fas fa-envelope"></i> <a href="mailto:hello@glit.com">Email: hello@glit.com</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-3 col-md-12 col-sm-12 col-12">
                                <div className="topbar-right-area">
                                    <div className="topbar-social-icons">
                                        <ul>
                                            <li><a href="https://www.facebook.com/" rel="noreferrer" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="https://www.linkedin.com/" rel="noreferrer" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="https://twitter.com/" rel="noreferrer" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="https://www.instagram.com/" rel="noreferrer" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Navbar />
        </div>
    );
};

export default Topheader;

