import React from 'react'
import { Link } from 'gatsby'

// Images 
import servicesImg from '../../assets/images/service/s1.jpg'
import servicesImg2 from '../../assets/images/service/s2.jpg'
import servicesImg3 from '../../assets/images/service/s3.jpg'
import servicesImg4 from '../../assets/images/service/s4.jpg'

const Services = () => {
    return (
        <div className="section-content">
            <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="service-card">
                        <div className="service-card-img">
                            <img 
                                src={servicesImg} 
                                alt="services" 
                            />
                            <Link to="/service-details"><i className="flaticon-business-plan"></i></Link>
                        </div>
                        <div className="service-card-text">
                            <h4><Link to="/service-details">Investment Planning And Strategy</Link></h4>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="service-card">
                        <div className="service-card-img">
                            <img 
                                src={servicesImg2} 
                                alt="services" 
                            />
                            <Link to="/service-details"><i className="flaticon-business-1"></i></Link>
                        </div>
                        <div className="service-card-text">
                            <h4><Link to="/service-details">International Business Opportunities</Link></h4>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="service-card">
                        <div className="service-card-img">
                            <img 
                                src={servicesImg3} 
                                alt="services" 
                            />
                            <Link to="/service-details"><i className="flaticon-document"></i></Link>
                        </div>
                        <div className="service-card-text">
                            <h4><Link to="/service-details">Strategic & Commercial Approach</Link></h4>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="service-card">
                        <div className="service-card-img">
                            <img 
                                src={servicesImg4} 
                                alt="services" 
                            />
                            <Link to="/service-details"><i className="flaticon-business-2"></i></Link>
                        </div>
                        <div className="service-card-text">
                            <h4><Link to="/service-details">Simple Business Answers And Solutions</Link></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services
