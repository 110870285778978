import React from "react"
import Layout from "../components/_App/Layout"
import Seo from "../components/_App/Seo"
import Topheader from "../components/Index/TopHeader"
import MainBanner from "../components/Index/MainBanner"
import Features from "../components/Index/Features"
import About from "../components/Index/About"
import Services from "../components/Common/Services"
import WhyOnly from "../components/Index/WhyOnly"
import Project from "../components/Common/Project"
import Achievements from "../components/Common/Achievements"
import Team from "../components/Common/Team"
import WhyChoose from "../components/Index/WhyChoose"
import Testimonials from "../components/Index/Testimonials"
import GetQuote from "../components/Index/GetQuote"
import BlogPost from "../components/Common/BlogPost"
import Partner from "../components/Common/Partner"
import Footer from "../components/_App/Footer"
import Copyright from "../components/_App/Copyright"

const IndexPage = () => (
    <Layout>
        <Seo title="Home" />
        
        <Topheader />

        <MainBanner />

        <Features />

        <About />

        <div className="service ptb-100 bg-f8f8f8">
            <div className="container">
                <div className="default-section-title default-section-title-middle">
                    <span>Our Services</span>   
                    <h3>Services For Business</h3>
                </div>
                <Services />
            </div>
        </div>

        <WhyOnly />

        <Project />

        <div className="bg-f8f8f8">
            <Achievements />
        </div>

        <Team />

        <WhyChoose />

        <Testimonials />

        <GetQuote />

        <BlogPost />

        <Partner />

        <Footer />

        <Copyright />
    </Layout>
)

export default IndexPage
