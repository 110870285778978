import React from 'react'

// Images 
import teamImg from '../../assets/images/team/t1.jpg'
import teamImg2 from '../../assets/images/team/t2.jpg'
import teamImg3 from '../../assets/images/team/t3.jpg'
import teamImg4 from '../../assets/images/team/t4.jpg'

const Team = () => {
    return (
        <section className="team ptb-100">
            <div className="container">
                <div className="default-section-title default-section-title-middle">
                    <span>Our Team</span>   
                    <h3>We Are Ready To Serving</h3>
                </div>
                <div className="section-content">
                    <div className="row justify-content-center">
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="team-card">
                                <img 
                                    src={teamImg} 
                                    alt="team"
                                />
                                <div className="team-card-text">
                                    <h4>David Monterio</h4>
                                    <p>Back End Developer</p>
                                    <ul>
                                        <li><a href="https://www.facebook.com/" rel="noreferrer" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="https://www.linkedin.com/" rel="noreferrer" target="_blank"><i className="fab fa-linkedin-in" ></i></a></li>
                                        <li><a href="https://twitter.com/" rel="noreferrer" target="_blank"><i className="fab fa-twitter" ></i></a></li>
                                        <li><a href="https://www.instagram.com/" rel="noreferrer" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="team-card">
                                <img 
                                    src={teamImg2} 
                                    alt="team"
                                />
                                <div className="team-card-text">
                                    <h4>Robert Martin</h4>
                                    <p>Front End Developer</p>
                                    <ul>
                                        <li><a href="https://www.facebook.com/" rel="noreferrer" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="https://www.linkedin.com/" rel="noreferrer" target="_blank"><i className="fab fa-linkedin-in" ></i></a></li>
                                        <li><a href="https://twitter.com/" rel="noreferrer" target="_blank"><i className="fab fa-twitter" ></i></a></li>
                                        <li><a href="https://www.instagram.com/" rel="noreferrer" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="team-card">
                                <img 
                                    src={teamImg3} 
                                    alt="team"
                                />
                                <div className="team-card-text">
                                    <h4>Norman Hartley</h4>
                                    <p>Bid Manager</p>
                                    <ul>
                                        <li><a href="https://www.facebook.com/" rel="noreferrer" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="https://www.linkedin.com/" rel="noreferrer" target="_blank"><i className="fab fa-linkedin-in" ></i></a></li>
                                        <li><a href="https://twitter.com/" rel="noreferrer" target="_blank"><i className="fab fa-twitter" ></i></a></li>
                                        <li><a href="https://www.instagram.com/" rel="noreferrer" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="team-card">
                                <img 
                                    src={teamImg4} 
                                    alt="team"
                                />
                                <div className="team-card-text">
                                    <h4>Leonard Lee</h4>
                                    <p>Team Lead</p>
                                    <ul>
                                        <li><a href="https://www.facebook.com/" rel="noreferrer" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="https://www.linkedin.com/" rel="noreferrer" target="_blank"><i className="fab fa-linkedin-in" ></i></a></li>
                                        <li><a href="https://twitter.com/" rel="noreferrer" target="_blank"><i className="fab fa-twitter" ></i></a></li>
                                        <li><a href="https://www.instagram.com/" rel="noreferrer" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Team
