import React from 'react'

// Images 
import testimonialImg from '../../assets/images/testimonial/tc2.jpg'
import testimonialImg2 from '../../assets/images/testimonial/tc3.jpg'
import testimonialImg3 from '../../assets/images/testimonial/tc4.jpg'
import testimonialImg4 from '../../assets/images/testimonial/tc5.jpg'
import testimonialImg5 from '../../assets/images/testimonial/tc6.jpg'

import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel3'));

const options = {
    loop: true,
    margin: 10,
    nav: true,
    responsiveClass: true,
    navText: [
        '<i class="fas fa-arrow-left"></i>', 
        '<i class="fas fa-arrow-right"></i>'
    ],
    dots: true,
    autoplay: true,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        992: {
            items: 2,
        },
    }
};


const Testimonials = () => {
    return (
        <section className="testimonials ptb-100">
            <div className="container">
                <div className="default-section-title default-section-title-middle">
                    <span>Testimonials</span>   
                    <h3>Our Happy Clients</h3>
                </div>
                <div className="section-content">
                    <OwlCarousel 
                        className="testimonial-slider-area owl-carousel"
                        {...options}
                        >
                        <div className="testimonial-card">
                            <img 
                                src={testimonialImg} 
                                alt="testimonial"
                            />
                            <div className="testimonial-card-text">
                                <p>“It is a long established fact that a reader will page when looking at its layout. The point of opposed to using 'Content here, content here', making it look like readable English.”</p>
                                <div className="testimonial-intro-area">
                                    <div className="testimonial-card-intro">
                                            <h4>Robert Cook</h4>
                                            <span>Front End Developer</span>
                                    </div>
                                    <div className="stars">
                                        <ul>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-card">
                            <img 
                                src={testimonialImg2} 
                                alt="testimonial"
                            />
                            <div className="testimonial-card-text">
                                <p>“It is a long established fact that a reader will page when looking at its layout. The point of opposed to using 'Content here, content here', making it look like readable English.”</p>
                                <div className="testimonial-intro-area">
                                    <div className="testimonial-card-intro">
                                            <h4>Danial Cook</h4>
                                            <span>Back End Developer</span>
                                    </div>
                                    <div className="stars">
                                        <ul>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-card">
                            <img 
                                src={testimonialImg3} 
                                alt="testimonial"
                            />
                            <div className="testimonial-card-text">
                                <p>“It is a long established fact that a reader will page when looking at its layout. The point of opposed to using 'Content here, content here', making it look like readable English.”</p>
                                <div className="testimonial-intro-area">
                                    <div className="testimonial-card-intro">
                                            <h4>Peter Smith</h4>
                                            <span>Front End Developer</span>
                                    </div>
                                    <div className="stars">
                                        <ul>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-card">
                            <img 
                                src={testimonialImg4} 
                                alt="testimonial"
                            />
                            <div className="testimonial-card-text">
                                <p>“It is a long established fact that a reader will page when looking at its layout. The point of opposed to using 'Content here, content here', making it look like readable English.”</p>
                                <div className="testimonial-intro-area">
                                    <div className="testimonial-card-intro">
                                        <h4>Robert Brown</h4>
                                        <span>Manager</span>
                                    </div>
                                    <div className="stars">
                                        <ul>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-card">
                            <img 
                                src={testimonialImg5} 
                                alt="testimonial"
                            />
                            <div className="testimonial-card-text">
                                <p>“It is a long established fact that a reader will page when looking at its layout. The point of opposed to using 'Content here, content here', making it look like readable English.”</p>
                                <div className="testimonial-intro-area">
                                    <div className="testimonial-card-intro">
                                            <h4>Stive Smith</h4>
                                            <span>Front End Developer</span>
                                    </div>
                                    <div className="stars">
                                        <ul>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        </section>
    )
}

export default Testimonials
