import React from 'react';
import { Link } from 'gatsby';

import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel3'));

const options = {
    loop: true,
    margin: 30,
    items: 1,
    nav: true,
    navText: [
        '<i class="fas fa-angle-left"></i>',
        '<i class="fas fa-angle-right"></i>'
    ],
    dots: false,
    autoplay: true,
    autoplayHoverPause: true,
    smartSpeed: 1000,
};

const MainBanner = () => {
    return (
        <div className="main-banner">
            <OwlCarousel 
                className="main-banner-slider-area owl-carousel slider-nav"
                {...options}
            >   
                <div className="main-banner-single-slider">
                    <div className="container">
                        <div className="banner-text-area">
                            <h6>We Are Digital Marketing Expert</h6>
                            <h1>We're Creative Business Solutions Agency</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temp gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                            <div className="banner-buttons">
                                <ul>
                                    <li><Link className="default-button" to="/contact">Contact Now <i className="fas fa-arrow-right"></i></Link></li>
                                    <li><Link className="default-button banner-button" to="/services">Explore More <i className="fas fa-arrow-right"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-banner-single-slider mbs-2">
                    <div className="container">
                        <div className="banner-text-area">
                            <h6>We Are Digital Marketing Expert</h6>
                            <h1>Stay Connected With Your Business</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temp gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                            <div className="banner-buttons">
                                <ul>
                                    <li><Link className="default-button" to="/contact">Contact Now <i className="fas fa-arrow-right"></i></Link></li>
                                    <li><Link className="default-button banner-button" to="/services">Explore More <i className="fas fa-arrow-right"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-banner-single-slider mbs-3">
                    <div className="container">
                        <div className="banner-text-area">
                            <h6>We Are Digital Marketing Expert</h6>
                            <h1>Make Your Own Startup Business Agency</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temp gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                            <div className="banner-buttons">
                                <ul>
                                    <li><Link className="default-button" to="/contact">Contact Now <i className="fas fa-arrow-right"></i></Link></li>
                                    <li><Link className="default-button banner-button" to="/services">Explore More <i className="fas fa-arrow-right"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel>
        </div> 
    );
};

export default MainBanner;