import React from 'react'

// Images 
import whyShape from '../../assets/images/shape/shape1.png'
import whyImg from '../../assets/images/why-we/ww2.jpg'

const WhyChoose = () => {
    return (
        <section className="why-we why-we-1 pt-100 bg-f8f8f8">
            <div className="shape">
                <div className="shape-img">
                    <img 
                        src={whyShape}
                        alt="Why Shape"
                    />
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="why-we-img-area">
                            <img 
                                src={whyImg} 
                                alt="Why"
                            />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="why-we-text-area why-we-text-area-1 pl-20">
                            <div className="default-section-title">
                                <span>Why Choose Us</span>   
                                <h3>We Offer Unique & Advanced Designs Centers</h3>
                            </div>
                            <div className="why-card-area">
                                <div className="row">
                                    <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                        <div className="why-we-card">
                                            <h4>Developing Strategy</h4>
                                            <p>It is advantageous when the dummy text is realistic lorem ipsum dolor.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                        <div className="why-we-card">
                                            <h4>Blazing Performance</h4>
                                            <p>It is advantageous when the dummy text is realistic lorem ipsum dolor.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                        <div className="why-we-card">
                                            <h4>Customer satisfaction</h4>
                                            <p>It is advantageous when the dummy text is realistic lorem ipsum dolor.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChoose
