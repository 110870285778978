import React from 'react'
import { Link } from 'gatsby'

const Features = () => {
    return (
        <section className="featurs ptb-100"> 
            <div className="container">
                <div className="default-section-title default-section-title-middle">
                    <span>Our Features</span>   
                    <h3>We Provide Creative Ideas</h3>
                </div>
                <div className="section-content">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="features-card">
                                <i className="flaticon-idea"></i>
                                <h4><Link to="/services">Unique Ideas & Solutions</Link></h4>
                                <p>Lorem ipsum dolor sit amet, cons ctetur adipiscing facilisis. </p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="features-card">
                                <i className="flaticon-business"></i>
                                <h4><Link to="/services">Create New Business</Link></h4>
                                <p>Lorem ipsum dolor sit amet, cons ctetur adipiscing facilisis. </p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="features-card">
                                <i className="flaticon-target"></i>
                                <h4><Link to="/services">Targeting & Positioning</Link></h4>
                                <p>Lorem ipsum dolor sit amet, cons ctetur adipiscing facilisis. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    )
}

export default Features
