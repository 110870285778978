import React from 'react'
import { Link } from 'gatsby'

// Images 
import projectImg from '../../assets/images/portfolio/p1.jpg'
import projectImg2 from '../../assets/images/portfolio/p2.jpg'
import projectImg3 from '../../assets/images/portfolio/p3.jpg'
import projectImg4 from '../../assets/images/portfolio/p4.jpg'
import projectImg5 from '../../assets/images/portfolio/p5.jpg'
import projectImg6 from '../../assets/images/portfolio/p6.jpg'

import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel3'));

const options = {
    loop: true,
    margin: 10,
    nav: false,
    responsiveClass: true,
    dots: false,
    autoplay: true,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 3,
        },
        1200: {
            items: 4,
        },
        1400: {
            items: 5,
        },
    }
};

const Project = () => {
    return (
        <section className="project ptb-100 bg-f8f8f8"> 
            <div className="container">
                <div className="default-section-title default-section-title-middle">
                    <span>Our Project</span>   
                    <h3>Discover The World Of Business</h3>
                </div>
            </div>

            <div className="section-content">
                <OwlCarousel 
                    className="portfolio-slider-area owl-carousel"
                    {...options}
                    >
                    <div className="portfolio-card">
                        <img 
                            src={projectImg} 
                            alt="project"
                        />
                        <div className="portfolio-card-text-area">
                            <Link to="/portfolio-details"> <i className="fas fa-arrow-right"></i></Link>
                            <h4><Link to="/portfolio-details">Project Strategy</Link></h4>
                            <p>Reducing risk and enhancing housing finance liquidity.</p>
                        </div>
                    </div>

                    <div className="portfolio-card">
                        <img 
                            src={projectImg2} 
                            alt="project"
                        />
                        <div className="portfolio-card-text-area">
                            <Link to="/portfolio-details"> <i className="fas fa-arrow-right"></i></Link>
                            <h4><Link to="/portfolio-details">Mission Successful</Link></h4>
                            <p>Reducing risk and enhancing housing finance liquidity.</p>
                        </div>
                    </div>

                    <div className="portfolio-card">
                        <img 
                            src={projectImg3} 
                            alt="project"
                        />
                        <div className="portfolio-card-text-area">
                            <Link to="/portfolio-details"> <i className="fas fa-arrow-right"></i></Link>
                            <h4><Link to="/portfolio-details">Project Summery</Link></h4>
                            <p>Reducing risk and enhancing housing finance liquidity.</p>
                        </div>
                    </div>

                    <div className="portfolio-card">
                        <img 
                            src={projectImg4} 
                            alt="project"
                        />
                        <div className="portfolio-card-text-area">
                            <Link to="/portfolio-details"> <i className="fas fa-arrow-right"></i></Link>
                            <h4><Link to="/portfolio-details">Team Meeting</Link></h4>
                            <p>Reducing risk and enhancing housing finance liquidity.</p>
                        </div>
                    </div>

                    <div className="portfolio-card">
                        <img 
                            src={projectImg5} 
                            alt="project"
                        />
                        <div className="portfolio-card-text-area">
                            <Link to="/portfolio-details"> <i className="fas fa-arrow-right"></i></Link>
                            <h4><Link to="/portfolio-details">Idea Sharing</Link></h4>
                            <p>Reducing risk and enhancing housing finance liquidity.</p>
                        </div>
                    </div>

                    <div className="portfolio-card">
                        <img 
                            src={projectImg6} 
                            alt="project"
                        />
                        <div className="portfolio-card-text-area">
                            <Link to="/portfolio-details"> <i className="fas fa-arrow-right"></i></Link>
                            <h4><Link to="/portfolio-details">Team Meeting</Link></h4>
                            <p>Reducing risk and enhancing housing finance liquidity.</p>
                        </div>
                    </div>
                </OwlCarousel>
            </div>
        </section>
    )
}

export default Project
