import React from 'react'
import ProgressBar from "@ramonak/react-progress-bar";

// Images 
import aboutImg from '../../assets/images/about/a1-1.jpg'
import aboutImg2 from '../../assets/images/about/a1-2.jpg'
import aboutImg3 from '../../assets/images/testimonial/tc1.jpg'



const About = () => {
    return (
        <section className="about pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-img-area">
                            <img className="main-img" 
                                src={aboutImg} 
                                alt="about" 
                            />
                            <img className="small-img" 
                                src={aboutImg2} 
                                alt="about" 
                            />
                            <div className="about-exp">
                                <h4>45+</h4>
                                <p>Years Of Experience</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-text-area pl-20">
                            <div className="default-section-title ">
                                <span>About Our Company</span>   
                                <h3>Here Comes An Intro About The Company</h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, do eiusmod tempo incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrice. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            <div className="progress-bar-area">
                                <div className="barfiller">
                                    <div className="label">Sales & Trading</div>
                                    <ProgressBar height="5px" bgColor="#3f9f42" borderRadius="20px" baseBgColor="transparent" completed={95} />
                                </div>
                                <div className="barfiller">
                                    <div className="label">Finance</div>
                                    <ProgressBar height="5px" bgColor="#3f9f42" borderRadius="20px" baseBgColor="transparent" completed={90} />
                                </div>
                                <div id="bar3" className="barfiller">
                                    <div className="label">Consulting</div>
                                    <ProgressBar height="5px" bgColor="#3f9f42" borderRadius="20px" baseBgColor="transparent" completed={75} />
                                </div>
                                <div id="bar4" className="barfiller">
                                    <div className="label">Digital Strategy</div>
                                    <ProgressBar height="5px" bgColor="#3f9f42" borderRadius="20px" baseBgColor="transparent" completed={80} />
                                </div>
                            </div>
                            <div className="about-intro">
                                <img 
                                    src={aboutImg3} 
                                    alt="about" 
                                />
                                <div className="at-text">
                                    <h4>Adam Rueter</h4>
                                    <p>Founder Manager</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
