import React from 'react'

const Achievements = () => {
    return (
        <section className="fun-facts fun-facts-1">
            <div className="container">
                <div className="fun-facts-content">
                    <div className="row align-items-center">
                        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="fun-facts-text-area pr-20">
                                <div className="default-section-title">
                                    <span>Our Awards</span>   
                                    <h3>Our Achievements</h3>
                                </div>
                                <p>Lorem ipsum dolor sit amet, elit, do risus commodo viverra facilisis. </p>
                            </div>
                        </div>

                        <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="fun-facts-card-area">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                                        <div className="fun-facts-card">
                                            <h2><span className="odometer" data-count="294">294</span></h2>
                                            <p>Project Done</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                                        <div className="fun-facts-card fcb">
                                            <h2><span className="odometer" data-count="987">987</span></h2>
                                            <p>Satisfied Customers</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                                        <div className="fun-facts-card">
                                            <h2><span className="odometer" data-count="60">60</span><span className="odo-text">K</span></h2>
                                            <p>Total Earned</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                                        <div className="fun-facts-card fcb">
                                            <h2><span className="odometer" data-count="1523">1523</span></h2>
                                            <p>Award Wins</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Achievements
